import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink, Link,
  Switch,
  Redirect
} from 'react-router-dom';


import LayoutSimple from './LayoutSimple';
import LayoutNavSimple from './LayoutNavSimple';
import LayoutNavDropdown from './LayoutNavDropdown';
import LayoutNavLeftDrawer from './LayoutNavLeftDrawer';
import LayoutSidebarLeftDrawerAndNavDropdown from './LayoutSidebarLeftDrawerAndNavDropdown';
import Layout1ColContentDynamic from './Layout1ColContentDynamic';
import LayoutDefault from './LayoutDefault';
import RouteAuthenticator from './RouteAuthenticator';
import User from './User';
import RouteLayout from './RouteLayout';
import NoMatch from './NoMatch';
import UserSignin from './UserSignin';
import UserSignInFacebook from './UserSignInFacebook';
import UserSignupForm from './UserSignupForm';
import UserSignupFormikComponent from './UserSignupFormikComponent';
import UserSignupFormik2 from './UserSignupFormik2';
import UserSignupConfirmForm from './UserSignupConfirmForm';
import UserForgotPasswordForm from './UserForgotPasswordForm';
import UserForgotPasswordSubmitCodeForm from './UserForgotPasswordSubmitCodeForm';
import UserDashboard from './UserDashboard'; 
import UserSignupSuccess from './UserSignupSuccess';
import OrgCreateForm from './OrgCreateForm';
import OrgsList from './OrgsList';
import Basic from './Forms/Basic';
import FieldLevelValidation from './Forms/FieldLevelValidation';

const RoutesLayout = () => {
  return (
    <div>
      <Switch>
        <Route exact={true} path="/" component={LayoutNavSimple} />
        <Route path="/about" component={LayoutNavDropdown} />
        <Route path="/blog" component={LayoutNavSimple} />
        <Route path="/contact" component={LayoutNavSimple} />
        <Route path="/login" component={LayoutNavSimple} />
        <Route path="/demos" component={LayoutNavSimple} />
        <Route path="/layout-simple" component={LayoutSimple} />
        <Route path="/layout-nav-simple" component={LayoutNavSimple} />
        <Route path="/layout-nav-dropdown" component={LayoutNavDropdown} />
        <Route path="/layout-nav-as-left-drawer" component={LayoutNavLeftDrawer} />
        <Route path="/layout-sidebar-left-drawer-and-nav-dropdown" component={LayoutSidebarLeftDrawerAndNavDropdown} />
        <Route path="/learn-redux" component={Layout1ColContentDynamic} />
        <Route path="/teams" component={Layout1ColContentDynamic} />
        <RouteLayout path="/signin" layout={LayoutDefault} component={UserSignin} />
        <RouteLayout path="/signup" exact={true} layout={LayoutDefault} component={UserSignupForm} />
        <RouteLayout path="/signup/formik" exact={true} layout={LayoutDefault} component={UserSignupFormikComponent} />
        <RouteLayout path="/signup/formik2" exact={true} layout={LayoutDefault} component={UserSignupFormik2} />
        <RouteLayout path="/forgot-password" layout={LayoutDefault} component={UserForgotPasswordForm} />
        <RouteLayout path="/forgot-password-submit-code" layout={LayoutDefault} component={UserForgotPasswordSubmitCodeForm} />
        <RouteLayout path="/signup-confirm" layout={LayoutDefault} component={UserSignupConfirmForm} />
        <RouteLayout path="/signup-success" layout={LayoutDefault} component={UserSignupSuccess} />
        <RouteLayout path="/create/org/public" layout={LayoutDefault} component={OrgCreateForm} />
        <RouteLayout path="/orgs/list" layout={LayoutDefault} component={OrgsList} />
        <RouteLayout path="/signin-federated" layout={LayoutDefault} component={UserSignInFacebook} />
        <RouteAuthenticator path="/user" component={User} layout={LayoutDefault}/>
        <RouteAuthenticator path="/dashboard" layout={LayoutDefault} component={UserDashboard} />
        <RouteAuthenticator path="/create/org" layout={LayoutDefault} component={OrgCreateForm} />
        <RouteAuthenticator path="/form/basic" layout={LayoutDefault} component={Basic} />
        <RouteAuthenticator path="/form/flv" layout={LayoutDefault} component={FieldLevelValidation} />
        <RouteLayout layout={LayoutDefault} component={NoMatch} />
      </Switch>
    </div>

  );
}
export default RoutesLayout;