import React from 'react';
import { connect } from 'react-redux';

const UserEmailCurrent = (props) => {
  return (
      <span>
        {props.userEmailCurrent}
      </span>
  ); 
}
const mapStateToProps = state => {
  console.log('current user email', state)
  return {
    userIsAuthenticated: state.user.userIsAuthenticated,
    userEmailCurrent: state.user.userEmailCurrent
  };
}
    
export default connect(mapStateToProps)(UserEmailCurrent);