import React, { Component, Fragment } from 'react';
import ListOrgs from './GqlListOrgs';
import { listOrganizations } from '../graphql/queries';
import Amplify, { API, graphqlOperation } from "aws-amplify";


class OrgsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgs: [],
    }
  }
  async componentWillMount() {
    const allOrgs = await API.graphql(graphqlOperation(listOrganizations));
    console.log(allOrgs);
    this.setState({ orgs: allOrgs.data.listOrganizations.items });
  }
  render() {
    const { orgs } = this.state;
    return (
      <div className="orgs-list">
        {
          orgs.map((org, idx) => <div key={idx}>{org.name}</div>)
        }
      </div>
    );
  }
}
export default OrgsList;




// const orgs = async() => (await ListOrgs());

// class OrgsList extends Component {
//   render() {
//     console.log('rocks 6767', orgs())
//     return (
//       <Fragment>
//         Rocks 5   
//       </Fragment>
//     )
//   }
// }        
// export default OrgsList;