import React from 'react';
import { connect } from 'react-redux';
import { Auth } from "aws-amplify";
import { userIsAuthenticated, stopCheckingUserSession, setUserEmailCurrent } from '../redux/actions/user';


class AuthenticatorSyncSessionToState extends React.Component {

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    console.log('rocks9991234');
    try {
      console.log('rocks9988ttt');
      const session = await Auth.currentSession();
      const cognitoUser = await Auth.currentAuthenticatedUser();
      console.log('cognitoUser', cognitoUser);
      console.log('cognitoUser email', cognitoUser.attributes.email);
      console.log('rocks998555');
      const email = cognitoUser.attributes.email;
      this.props.setUserAsAuthenticated();
      this.props.stopCheckingUserSession();  
      this.props.setUserEmailCurrent(email);
      // console.log(Auth.currentCredentials());
      // console.log('current session rocks8', Auth.currentSession());
      // Auth.currentAuthenticatedUser()
      //   .then(user => console.log(user))
      //   .catch(err => console.log(err));
    }
    catch(e) {
      
      console.log('rocks777');
      console.log('error user', e);
      if (e !== 'No current user') {
        console.log(e);
      }
      this.props.stopCheckingUserSession(); 
    }  
     
  }

  render () {
    return null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserAsAuthenticated: () => {
      dispatch(userIsAuthenticated());
    },
    stopCheckingUserSession: () => {
      dispatch(stopCheckingUserSession());
    },
    setUserEmailCurrent: (email) => {
      dispatch(setUserEmailCurrent(email));
    }
  }
}


export default connect(null, mapDispatchToProps)(AuthenticatorSyncSessionToState);
