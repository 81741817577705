import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { userIsAuthenticated } from "../redux/actions/index";
import AnonymousOnly from './AnonymousOnly';
import AuthenticatedOnly from './AuthenticatedOnly';
import { Redirect } from 'react-router-dom';
// import { Form } from 'sematic-us-react';

class UserSignupConfirmForm extends React.Component {
  constructor () {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.state = {
      email: localStorage.getItem('bluuboardSignupEmail'),
      code: ''
    }
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    console.log('attempting to log in');
    try {
      // await Auth.signUp(this.state.email, this.state.password);
      Auth.confirmSignUp(this.state.email, this.state.code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true    
      });
    
      console.log('logged in');
      //alert("Logged in");
      console.log(this.props);
      this.props.userIsAuthenticated();
      console.log(this.props.user);
    } catch (e) {
      console.log(e.message);
      console.log('error in logging in');
    }
  }

  validateForm() {
    return undefined !== this.state.code && this.state.code.length > 0;
  }

  render () {
    console.log('rocks signup email', this.props.signupEmail);
    console.log('rocks signup email', this.props);
    console.log('signup email:', localStorage.getItem('bluuboardSignupEmail'));
    return (
      <Fragment>
        <AnonymousOnly>
          <div className="login-form">
            <h1>Signup Verifcation</h1>
            <form onSubmit={this.handleSubmit}>
              <div>
                <StyledLabel>Email</StyledLabel>
                <StyledInputEmail
                  type="email"
                  id="email"
                  value={this.state.email}
                  onChange={this.handleChange} />
              </div>
              <div>
                <StyledLabel>Verificaion Code</StyledLabel>
                <StyledInputCode
                  autoFocus
                  type="text"
                  id="code"
                  value={this.state.code}
                  onChange={this.handleChange} />
              </div>
              <button disabled={!this.validateForm()} type="submit">
                Verify
              </button>  

            </form>  
            <div>
              signup Email is : {this.state.email}
            </div>
          </div>
        </AnonymousOnly>
        <AuthenticatedOnly>
          <Redirect to="/dashboard" />
        </AuthenticatedOnly>
      </Fragment> 

    );
  }
}

const mapStateToProps = state => {
  return {
    signupEmail: state.user.signupEmail
  };
}

const mapDispatchToProps = dispatch => {
  return {
    userIsAuthenticated: () => {
      dispatch(userIsAuthenticated())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSignupConfirmForm);

const StyledLabel = styled.label`
  max-width: 100%;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
`;
const StyledInputEmail = styled.input`
  max-width: 100%;
  font-size: 16px;
  padding: 5px 10px;
  margin-bottom: 1em;
`;
const StyledInputCode = styled.input`
  max-width: 100%;
  font-size: 16px;
  padding: 5px 10px;
  margin-bottom: 1em;
`;
// ==========================================================================

