import React, { Fragment } from 'react';
import { connect } from 'react-redux';

class AuthenticatedOnly extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    if (this.props.userIsAuthenticated) {
      return (
        <Fragment>
          {this.props.children}
        </Fragment>
      )  
    }
    
    return (
      null
    )  
  }  
}

const mapStateToProps = state => {
  return {
    userIsAuthenticated: state.user.userIsAuthenticated
  };
}
  
    
export default connect(mapStateToProps)(AuthenticatedOnly);

  

