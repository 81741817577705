import React from 'react';
import UserCognito from './UserCognito'
import UserOrgs from './UserOrgs'

class UserDashboard extends React.Component {
  render() {
    let cred
    console.log('rocks cred 0')
    const user = async() => {
      // const cred = await UserCognito();
      console.log('rocks 888888')
      //console.log('rocks cred', cred)
      cred  = await UserCognito();
      // return await UserCognito();
      console.log('rocks iii', cred)
    }  
    
    return (
        <div>
          this is the User dashboard
          <UserOrgs />    
          
          
        </div>
    );
  }  
}

export default UserDashboard;