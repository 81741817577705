import React from 'react';
import styled from 'styled-components';

const Error = (props) => {
  return (
    <StyledError className="error">
      { props.children }
    </StyledError>
  );
}

export default Error;


const StyledError = styled.span`
  color: red;
  font-size: 14px;
  font-weight: 600;
`;
