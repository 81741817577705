import React from 'react';
import UserSignupFormik from './UserSignupFormik';

// Helper for the demo
import { DisplayFormikState } from './formik-demo';

const UserSignupFormikComponent = () => (


    <UserSignupFormik user={{ given_name: '', family_name: '', email: '', password: '' }} />


);

export default UserSignupFormikComponent;