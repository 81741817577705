import React from 'react';
import styled from "styled-components";
import UserLogout from './UserLogout';
import UserSigninLink from './UserSigninLink'; 
import {
  BrowserRouter as Router,
  Route,
  NavLink, Link,
  Switch,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import { ColorBgSidebarMobile } from './Variables'; 
import AnonymousOnly from './AnonymousOnly';
import AuthenticatedOnly from './AuthenticatedOnly';
import UserEmailCurrent from './UserEmailCurrent';

// const RenderedComponentDemo = () => <ComponentDemo>Hello Demos!</ComponentDemo>;


const NavSimple = (props) => {

  return (
      <MainNavTag className="nav-primary nav-simple" aria-label="Primary Navigation" bgColor={ColorBgSidebarMobile}>
        <MainNavUl>
          <MainNavLink><NavLink exact={true} activeClassName='is-active' to="/">Home</NavLink></MainNavLink>
          <MainNavLink><NavLink exact={true} activeClassName='is-active' to="/about">About</NavLink></MainNavLink>
          <MainNavLink><NavLink exact={true} activeClassName='is-active' to="/blog">Blog</NavLink></MainNavLink>
          <MainNavLink><NavLink exact={true} activeClassName='is-active' to="/contact">Contact</NavLink></MainNavLink>
          <AnonymousOnly><li><UserSigninLink /></li></AnonymousOnly>
          <AuthenticatedOnly><li><UserLogout /></li></AuthenticatedOnly>

          <AuthenticatedOnly><li><UserEmailCurrent /></li></AuthenticatedOnly>
        </MainNavUl>
      </MainNavTag>
  );
}

const mapStateToProps = state => {
  return {
    currentUserEmail: state.currentUserEmail,
  };
}
  
export default connect(mapStateToProps)(NavSimple);

// 'css in js' styling 
const MainNavUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  li {
    color: #fff;
    margin: 0 1em;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
`;
const MainNavLink = styled.li`
  color: #fff;
  a { 
    color: #fff; 
    text-decoration: none;
  }
`;

const MainNavTag = styled.nav`
  position: relative;
`;