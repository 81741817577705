
import React, { Component } from 'react';
import { graphqlOperation, API } from 'aws-amplify';
import retry from 'async-retry';


export const catchError = (err) => {
  console.log('err', err)
}

const GqlRetry = async (query, variables) => {

  return await retry(
    async bail => {
        console.log('Sending GraphQL operation', {query: query, vars: variables});
        const response = await API.graphql(graphqlOperation(query, variables))
        console.log('GraphQL result xx', {result: response, query: query, vars: variables})
        if (403 === response.status) {
          console.log('response 403', response)
          // don't retry upon 403
          bail(new Error('Unauthorized'))
          return
        }
        return response
    },
    {
        retries: 0,
        onRetry: catchError
    }
  )
} 

export default GqlRetry;