import GqlRetry, { catchError} from './GraphqlRetry';
import { getUrlAliasOrg } from '../graphql/queries';
export const validateUrlAlias = async (url, setErrors) => {
  let variables = {id: url}
  const resp = await GqlRetry(getUrlAliasOrg, variables);
  if (resp.data.getUrlAliasOrg == null) {
    setErrors({ urlaliasSuccess: 'Great. That alias is available.' })
    return true
  }
  else {
    if( typeof resp.data.getUrlAliasOrg.id != "undefined") {
      setErrors({ url_alias: 'That url is already taken.' })
      return false
    }  
  }
  return true
}