import React, { Component, Fragment } from 'react';
import { Formik, FormikProps, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Auth } from "aws-amplify";

const formSchema = Yup.object().shape({
  given_name: Yup.string()
    .min(2, "First should be longer than that")
    .required('First name is required.'),
  family_name: Yup.string()
    .min(2, "CLast name should longer than that")
    .required('Last name is required.'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required!'),
  password: Yup.string().required('Password is required'),
  password_confirm: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords don't match")
      .required('Password confirm is required')
})


class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleSubmit = () => {
   
  }
  handleSubmit = async (payload, { setSubmitting }) => {
    // event.preventDefault();
    console.log('attempting to log in');
    setSubmitting(true);
    try {
      // this.setState({
      //   async_action: 'loading'
      // });
      console.log('signup paylod', payload)
      await Auth.signUp({
        username: payload.email, 
        password: payload.password,
        attributes: {
          given_name: payload.given_name,
          family_name: payload.family_name
        }
      });
      console.log('succesfully signed up');
      //alert("Logged in");
      //console.log(this.props);
      // this.setState({
      //   async_action: 'success',
      // });
      // this.props.rememberEmail(this.state.email);
      localStorage.setItem('bluuboardSignupEmail', payload.email);
      

    } catch (e) {
      console.log(e.message);
      console.log('error in signing up');
      // this.setState({
      //   async_action: 'error',
      //   error_message: e.message
      // });
      if (e.message == "An account with the given email already exists.") {
        console.log('rocks remember email');
        // this.props.rememberEmail(this.state.email);
        localStorage.setItem('bluuboardSignupEmail', payload.email);
        //this.props.history.push('/signup-confirm');
        //this.context.router.history.push(`/signup-confirm`);
      } 
    }
  }


  render() {
    return (
      <Formik
        validationSchema={formSchema}    
        initialValues = {{given_name: '', family_name: '', email: '', password: '', password_confirm: ''}}
        onSubmit={this.handleSubmit}
        render={(formProps: FormikProps) => {
          console.log('formProps', formProps)
          return(
            <Form>
              <Field 
                type="text" 
                name="given_name" 
                placeholder="First name"
              />
              <ErrorMessage name="given_name" />
              <Field 
                type="text" 
                name="family_name" 
                placeholder="Last name"
              />
              <ErrorMessage name="family_name" />
              <Field 
                type="email" 
                name="email" 
                placeholder="Email"
              /> 
              <ErrorMessage name="email" />
              <Field 
                type="password" 
                name="password" 
                placeholder="Password"
              />
              <Field 
                type="password" 
                name="password_confirm" 
                placeholder="Confirm Password"
              />  
              <div><ErrorMessage name="password_confirm" /></div>
              <button 
                type="submit" 
                disabled={formProps.isSubmitting}>
                  Sign up
              </button>
            </Form>
          );
      }}
      />      
    )
  }    
}  


export default SignupForm;