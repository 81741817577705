import React from 'react';
import { NavLink } from 'react-router-dom';
import AnonymousOnly from './AnonymousOnly';


class UserForgotPasswordLink extends React.Component {

  render() {
    return (
      <AnonymousOnly>
        <NavLink exact={true} to="/forgot-password">Forgot password</NavLink>
      </AnonymousOnly>  
    );
  }  
}



export default UserForgotPasswordLink;