import React from 'react';



const ContentSimple = () => {
  return (
    <div>
      <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac venenatis tellus. Vivamus maximus consequat leo, eu pellentesque nulla fermentum nec. Donec ullamcorper lorem vitae risus aliquet sagittis. Integer sit amet turpis id tellus dapibus pellentesque ut at massa. Nunc id metus in elit iaculis venenatis et sed nunc. Etiam consectetur lectus eget nibh semper porta. Morbi et sodales neque. Quisque fermentum nisl ex, ut hendrerit libero sollicitudin vel. Integer consequat eros in purus scelerisque, a ultrices mi tincidunt. Suspendisse maximus tincidunt magna, facilisis commodo nisl volutpat ut. Curabitur id scelerisque tortor, a fringilla dolor. Nam bibendum varius lorem a rutrum. Aenean non ex mattis, faucibus arcu sed, aliquam nisi. Pellentesque felis justo, tincidunt a lacus nec, vulputate cursus urna.
      </p>
      <p>
      Nunc vel sem ut ipsum cursus rhoncus. Phasellus convallis dictum mollis. Aliquam sed elementum augue, vitae volutpat enim. Suspendisse consectetur posuere urna, eget finibus nibh aliquet vitae. Sed sed lorem lectus. Mauris rutrum sapien sit amet turpis lacinia, ut euismod nibh sodales. Ut ac fermentum nisi. Sed aliquet vestibulum nisi sed cursus. Fusce vel sagittis sapien, sed bibendum augue.
      </p>
      <p>
      Vestibulum viverra luctus magna, eu condimentum turpis sodales ac. Proin dapibus sem ac rhoncus porta. In hac habitasse platea dictumst. Sed auctor est eget porttitor fringilla. In auctor velit non tempor sagittis. Phasellus cursus vehicula ligula id gravida. Pellentesque consectetur felis ut nulla luctus, vel euismod felis egestas. Morbi maximus sollicitudin leo, a pellentesque tellus vulputate ac. Aliquam ac auctor neque, et malesuada dolor. Duis non velit ipsum. Duis feugiat dapibus lacus quis pretium.
      </p>
      <p>
      Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce blandit lorem imperdiet varius placerat. Morbi cursus nisl ac tortor finibus hendrerit. Ut a mi non velit fermentum iaculis vel sed enim. Morbi ornare rutrum volutpat. Cras luctus, tellus nec iaculis tristique, magna tortor laoreet lacus, imperdiet consequat turpis purus ut est. Cras hendrerit ante non ante viverra, at vehicula leo porttitor. In metus turpis, aliquam a enim ac, tempus suscipit massa. Donec pharetra enim vitae fermentum hendrerit. Nulla non neque scelerisque lorem accumsan efficitur.
      </p>
      <p>
      Aenean eu sapien facilisis, interdum neque eget, vestibulum libero. Suspendisse vulputate nec orci quis congue. Duis eget fringilla orci, et vehicula dolor. Proin fermentum ultricies nisi, sed tincidunt elit varius in. Etiam ut feugiat sapien. Donec facilisis mi mi, eu sollicitudin eros viverra a. Proin ut quam volutpat nisi fermentum elementum a quis ipsum. Vivamus non placerat magna. Cras porta iaculis eros, eu tincidunt magna egestas ac. Nam placerat arcu tristique, malesuada odio et, dignissim ipsum. Morbi et condimentum felis. Proin laoreet semper lorem, in lacinia orci pretium id. Nulla erat elit, auctor eu nisl sit amet, tincidunt lacinia mauris.
      </p>
      <p>
      Aliquam et velit sed velit suscipit posuere ac ut neque. Nulla neque nisi, semper eget urna at, rhoncus tristique arcu. In lobortis dui nec lorem fringilla ultrices. Nam quis urna in tortor porttitor sodales non id metus. Maecenas ultrices est quis tortor posuere, at iaculis tellus vehicula. Integer sed magna mauris. Praesent dignissim accumsan orci sit amet luctus. Quisque nec maximus justo. Aenean luctus ipsum enim, in suscipit sem aliquet et.
      </p>
      <p>
      Aenean sit amet nisl vitae mauris tristique vulputate ac ac nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla sodales diam ac felis feugiat, quis ullamcorper massa vulputate. Donec laoreet accumsan aliquet. Curabitur blandit consectetur sem vitae scelerisque. Nulla facilisi. Pellentesque a bibendum odio. Suspendisse potenti.
      </p>
    </div>   
  );
}
export default ContentSimple;

