// import './formik-demo.css';
import React from 'react';
import { render } from 'react-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
// Helper for the demo
import { MoreResources, DisplayFormikState } from './formik-demo';
import TextInput from './Forms/TextInput';
import { Auth } from "aws-amplify";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    given_name: Yup.string()
      .min(2, "C'mon, your name is longer than that")
      .required('First name is required.'),
    family_name: Yup.string()
      .min(2, "C'mon, your name is longer than that")
      .required('Last name is required.'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
  }),

  mapPropsToValues: ({ user }) => ({
    ...user,
  }),
  // handleSubmit: (payload, { setSubmitting }) => {
  //   alert(payload.email);
  //   console.log(payload);
  //   setSubmitting(false);

  // },
  handleSubmit: async (payload, { setSubmitting }) => {
    // event.preventDefault();
    console.log('attempting to log in');
    setSubmitting(true);
    try {
      // this.setState({
      //   async_action: 'loading'
      // });
      console.log('signup paylod', payload)
      await Auth.signUp({
        username: payload.email, 
        password: payload.password,
        attributes: {
          given_name: payload.given_name,
          family_name: payload.family_name
        }
      });
      console.log('succesfully signed up');
      //alert("Logged in");
      //console.log(this.props);
      // this.setState({
      //   async_action: 'success',
      // });
      // this.props.rememberEmail(this.state.email);
      localStorage.setItem('bluuboardSignupEmail', payload.email);
      

    } catch (e) {
      console.log(e.message);
      console.log('error in signing up');
      // this.setState({
      //   async_action: 'error',
      //   error_message: e.message
      // });
      if (e.message == "An account with the given email already exists.") {
        console.log('rocks remember email');
        // this.props.rememberEmail(this.state.email);
        localStorage.setItem('bluuboardSignupEmail', payload.email);
        //this.props.history.push('/signup-confirm');
        //this.context.router.history.push(`/signup-confirm`);
      }
      
    }
    // setSubmitting(false);
  },

  displayName: 'SignupForm',
});


const SignupForm = props => {
  const {
    values,
    touched,
    errors,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    isSubmitting,
  } = props;
  let test
  console.log('rocks kl', {isSubmitting})
  if ({isSubmitting} == true) {
    test = <div>Rocks submitting</div>
  } else {
    test = <div>rocks finished sbumitting</div>
  }
  return (
    

    <form onSubmit={handleSubmit}>
      {test}
      <div></div>
      <TextInput
        id="given_name"
        type="text"
        label="First Name"
        placeholder="John"
        error={touched.given_name && errors.given_name}
        value={values.given_name}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        id="family_name"
        type="text"
        label="Last Name"
        placeholder="Doe"
        error={touched.family_name && errors.family_name}
        value={values.family_name}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        id="email"
        type="email"
        label="Email"
        placeholder="Enter your email"
        error={touched.email && errors.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextInput
        id="password"
        type="password"
        label="Password"
        placeholder="Enter password"
        error={touched.password && errors.password}
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <button
        type="button"
        className="outline"
        onClick={handleReset}
        disabled={!dirty || isSubmitting}
      >
        Reset
      </button>
      <button type="submit" disabled={isSubmitting}>
        Submit
      </button>
      <DisplayFormikState {...props} />
    </form>
  );
};

const UserSignupFormik = formikEnhancer(SignupForm);

export default UserSignupFormik;