import React, { Component, Fragment } from 'react';
import { listOrganizations } from '../graphql/queries';
import Amplify, { API, graphqlOperation } from "aws-amplify";
import GqlRetry, { catchError} from './GraphqlRetry';

const UserOrgs = (props) => {
  let filter = {

    owner_orig: {
      eq: 'dummy4'
    }    
  }
  let limit = 20;
  let variables = {filter, limit}
  const myOrgs = async() => {
    return await GqlRetry(listOrganizations, variables);
  
  }
  console.log('myOrgs', myOrgs());
    return (
      <div>
        my orgs
      </div>
  ); 
}  

// const myOrgs = async () => {
//     return await API.graphql(graphqlOperation(listOrganizations));
//   }  
//   console.log('myOrgs', myOrgs());
//   return (
//       <div>
//         my orgs
//       </div>
//   ); 
// }

export default UserOrgs



// class OrgsList extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       orgs: [],
//     }
//   }
//   async componentWillMount() {
//     const allOrgs = await API.graphql(graphqlOperation(listOrganizations));
//     console.log(allOrgs);
//     this.setState({ orgs: allOrgs.data.listOrganizations.items });
//   }
//   render() {
//     const { orgs } = this.state;
//     return (
//       <div className="orgs-list">
//         {
//           orgs.map((org, idx) => <div key={idx}>{org.name}</div>)
//         }
//       </div>
//     );
//   }
// }
// export default OrgsList;


