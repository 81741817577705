import { DECREMENT } from '../constants/action-types';
import { INCREMENT } from '../constants/action-types';

const initialState = {
  count: 0
}

const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT:
    console.log('incrment reducer', { ...state, count: state.count + 1 });
      return { ...state, count: state.count + 1 }
    case DECREMENT:
      return { ...state, count: state.count - 1} 
    default: 
      return state;
  }  
}  

export default counterReducer;