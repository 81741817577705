
export const ADD_ARTICLE = 'ADD_ARTICLE';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const LOGIN_COGNITO = 'LOGIN_COGNITO';
export const USER_IS_AUTHENTICATED = 'USER_IS_AUTHENTICATED';
export const STOP_CHECKING_USER_SESSION = 'STOP_CHECKING_USER_SESSION';
export const LOGOUT = 'LOGOUT';
export const SIGNUP_EMAIL = 'SIGNUP_EMAIL';
export const SET_CURRENT_USER_EMAIL = 'SET_CURRENT_USER_EMAIL';