import { LOGIN_COGNITO, 
  LOGOUT , 
  USER_IS_AUTHENTICATED, 
  STOP_CHECKING_USER_SESSION,
  SIGNUP_EMAIL,
  SET_CURRENT_USER_EMAIL
 } from '../constants/action-types';


const initialState = {
  userEmailCurrent: '',
  userIsAuthenticated: false,
  userCheckingSession: false,
  signupEmail: ''
};


const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_COGNITO:
      return { ...state, user: {name: 'Rocks'}}
    case LOGOUT:
      return { ...state, userIsAuthenticated: false}   
    case USER_IS_AUTHENTICATED:
      return { ...state, userIsAuthenticated: true}    
    case STOP_CHECKING_USER_SESSION:
      return { ...state, userCheckingSession: false} 
    case SIGNUP_EMAIL:     
      return { ...state, signupEmail: action.payload}  
    case SET_CURRENT_USER_EMAIL:     
    console.log('action current user email', action)
      return { ...state, userEmailCurrent: action.payload}      
    default: 
      return state;
  }  
}  

export default userReducer;