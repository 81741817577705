import React from 'react';
import styled from 'styled-components';

const StylingFormField = (props) => {
  return (
    <StyledFormField>
      {props.children}
    </StyledFormField>
  );
}

export default StylingFormField;

const StyledFormField = styled.div`
  input {
    margin-bottom: 0;
  }
`;