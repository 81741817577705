import { TOGGLE_MENU } from '../constants/action-types';

const initialState = {
  value: false  
};


const toggleMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
    console.log('toggleMenu state', state);
      return { ...state, value: !state.value};     
    default: 
      return state;
  }  
}  

export default toggleMenuReducer;