import React from 'react';



const NoMatch = () => {
  return (
      <h1  className="page-404">
        Page not found
      </h1>
  );
}

export default NoMatch;