// eslint-disable
// this is an auto generated file. This will be overwritten

export const addOrgUrlAlias = `mutation AddOrgUrlAlias($input: CreateOrganizationInput!) {
  addOrgUrlAlias(input: $input) {
    id
    name
    tags
    sports
    sports_association
    division
    skillLevel
    ageBracket
    contact {
      items {
        id
        name
        email
        phone
        others
      }
      nextToken
    }
    address {
      id
      street
      city
      state
      country
      zipcode
    }
    owner_orig
    owner
    org_administrators
    org_editors
    url_alias
  }
}
`;
export const createOrganization = `mutation CreateOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    id
    name
    tags
    sports
    sports_association
    division
    skillLevel
    ageBracket
    contact {
      items {
        id
        name
        email
        phone
        others
      }
      nextToken
    }
    address {
      id
      street
      city
      state
      country
      zipcode
    }
    owner_orig
    owner
    org_administrators
    org_editors
    url_alias
  }
}
`;
export const updateOrganization = `mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    id
    name
    tags
    sports
    sports_association
    division
    skillLevel
    ageBracket
    contact {
      items {
        id
        name
        email
        phone
        others
      }
      nextToken
    }
    address {
      id
      street
      city
      state
      country
      zipcode
    }
    owner_orig
    owner
    org_administrators
    org_editors
    url_alias
  }
}
`;
export const deleteOrganization = `mutation DeleteOrganization($input: DeleteOrganizationInput!) {
  deleteOrganization(input: $input) {
    id
    name
    tags
    sports
    sports_association
    division
    skillLevel
    ageBracket
    contact {
      items {
        id
        name
        email
        phone
        others
      }
      nextToken
    }
    address {
      id
      street
      city
      state
      country
      zipcode
    }
    owner_orig
    owner
    org_administrators
    org_editors
    url_alias
  }
}
`;
export const createUrlAliasOrg = `mutation CreateUrlAliasOrg($input: CreateUrlAliasOrgInput!) {
  createUrlAliasOrg(input: $input) {
    id
    entity_id
    redirect
    version
  }
}
`;
export const updateUrlAliasOrg = `mutation UpdateUrlAliasOrg($input: UpdateUrlAliasOrgInput!) {
  updateUrlAliasOrg(input: $input) {
    id
    entity_id
    redirect
    version
  }
}
`;
export const deleteUrlAliasOrg = `mutation DeleteUrlAliasOrg($input: DeleteUrlAliasOrgInput!) {
  deleteUrlAliasOrg(input: $input) {
    id
    entity_id
    redirect
    version
  }
}
`;
export const createUrlAliasEvt = `mutation CreateUrlAliasEvt($input: CreateUrlAliasEvtInput!) {
  createUrlAliasEvt(input: $input) {
    id
    entity_id
    version
  }
}
`;
export const updateUrlAliasEvt = `mutation UpdateUrlAliasEvt($input: UpdateUrlAliasEvtInput!) {
  updateUrlAliasEvt(input: $input) {
    id
    entity_id
    version
  }
}
`;
export const deleteUrlAliasEvt = `mutation DeleteUrlAliasEvt($input: DeleteUrlAliasEvtInput!) {
  deleteUrlAliasEvt(input: $input) {
    id
    entity_id
    version
  }
}
`;
export const createAddress = `mutation CreateAddress($input: CreateAddressInput!) {
  createAddress(input: $input) {
    id
    street
    city
    state
    country
    zipcode
  }
}
`;
export const updateAddress = `mutation UpdateAddress($input: UpdateAddressInput!) {
  updateAddress(input: $input) {
    id
    street
    city
    state
    country
    zipcode
  }
}
`;
export const deleteAddress = `mutation DeleteAddress($input: DeleteAddressInput!) {
  deleteAddress(input: $input) {
    id
    street
    city
    state
    country
    zipcode
  }
}
`;
export const createContact = `mutation CreateContact($input: CreateContactInput!) {
  createContact(input: $input) {
    id
    name
    email
    phone
    others
    organization {
      id
      name
      tags
      sports
      sports_association
      division
      skillLevel
      ageBracket
      contact {
        nextToken
      }
      address {
        id
        street
        city
        state
        country
        zipcode
      }
      owner_orig
      owner
      org_administrators
      org_editors
      url_alias
    }
  }
}
`;
export const updateContact = `mutation UpdateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    id
    name
    email
    phone
    others
    organization {
      id
      name
      tags
      sports
      sports_association
      division
      skillLevel
      ageBracket
      contact {
        nextToken
      }
      address {
        id
        street
        city
        state
        country
        zipcode
      }
      owner_orig
      owner
      org_administrators
      org_editors
      url_alias
    }
  }
}
`;
export const deleteContact = `mutation DeleteContact($input: DeleteContactInput!) {
  deleteContact(input: $input) {
    id
    name
    email
    phone
    others
    organization {
      id
      name
      tags
      sports
      sports_association
      division
      skillLevel
      ageBracket
      contact {
        nextToken
      }
      address {
        id
        street
        city
        state
        country
        zipcode
      }
      owner_orig
      owner
      org_administrators
      org_editors
      url_alias
    }
  }
}
`;
