import React, { Component } from 'react';
import './App.css';
import Amplify, { API, graphqlOperation } from "aws-amplify";

import RoutesLayout from './RoutesLayout';
import ApolloClient from "apollo-boost";
import { ApolloProvider, Query } from "react-apollo";
import { ClassLayoutMainDesktop } from './Variables';
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom';
import appSyncConfig from "../aws-exports-manual";
import AuthenticatorSyncSessionToState from './AuthenticatorSyncSessionToState';

const class_name = ClassLayoutMainDesktop;

const client = new ApolloClient();


const App = () => (
  <Router>      
    <div className={`App  ${class_name}`}>
      <RoutesLayout />
    </div>
  </Router>
)


const WithProvider = () => (
  <ApolloProvider client={client}>
    <AuthenticatorSyncSessionToState />
    <App />

  </ApolloProvider>
);

export default WithProvider;

// class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       orgs: [],
//     }
//   }
//   async componentWillMount() {
//     const allOrgs = await API.graphql(graphqlOperation(listOrganizations));
//     console.log(allOrgs);
//     this.setState({ orgs: allOrgs.data.listOrganizations.items });
//   }
//   render() {
//     const { orgs } = this.state;
//     return (
//       <div className="App">
//         <header className="App-header">
//           <img src={logo} className="App-logo" alt="logo" />
//           <h1 className="App-title">Welcome to React using AWS and apollo</h1>
//         </header>
//         <div className="App-intro">
//           {
//             orgs.map((org, idx) => <div key={idx}>{org.name}</div>)
//           }
//         </div>
//       </div>
//     );
//   }
// }



Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    userPoolId: appSyncConfig.aws_user_pools_id,
    // REQUIRED - Amazon Cognito Region
    region: appSyncConfig.aws_appsync_region,
    // OPTIONAL - Amazon Cognito User Pool ID
    identityPoolId: appSyncConfig.IDENTITY_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: appSyncConfig.aws_user_pools_web_client_id,
  },
  API: {
    'aws_appsync_graphqlEndpoint': appSyncConfig.aws_appsync_graphqlEndpoint,
    'aws_appsync_region': appSyncConfig.aws_appsync_region,
    'aws_appsync_authenticationType': 'AWS_IAM',
  },
});
