
const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:27b89339-7f1b-4f02-9f2c-db2de732d49b",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_kBzgKzY5U",
  "aws_user_pools_web_client_id": "4daj9dgn9j9si8jsnu9v7b3l03",
  "oauth": {
      "domain": "redacted",
      "scope": [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": "http://localhost:3000/,https://bluuboard-20181230100123--hostingbucket.s3-website-us-east-1.amazonaws.com/,https://bluuboard.com/",
      "redirectSignOut": "http://localhost:3000/,https://bluuboard-20181230100123--hostingbucket.s3-website-us-east-1.amazonaws.com/,https://bluuboard.com/",
      "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_appsync_graphqlEndpoint": "https://f4656ftxevdo3k6jsf5jp5o55q.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AWS_IAM",
  "aws_appsync_apiKey": "redacted",
  "aws_content_delivery_bucket": "bluuboard-20181230100123--hostingbucket",
  "aws_content_delivery_bucket_region": "us-east-1",
  "aws_content_delivery_url": "http://bluuboard-20181230100123--hostingbucket.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
