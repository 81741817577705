import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Icon, Form } from 'semantic-ui-react';
import { graphqlOperation, API } from 'aws-amplify';
import retry from 'async-retry';
import { addOrgUrlAlias } from '../graphql/mutations';
import { sports } from './VariablesEnum';
import { Formik, Field, Form as FormFormik, ErrorMessage } from 'formik';
import styled from 'styled-components';
import StylingFormField from './StylingFormField';
import { valueFromAST } from 'graphql';
import { validateUrlAlias } from './GqlCheckUrlAlias'; 
import { Debug } from './Debug';

const QNewOrg = addOrgUrlAlias;

const catchError = (err) => {
  console.log('err', err)
}

const GqlRetry = async (query, variables) => {
  return await retry(
    async bail => {
        console.log('Sending GraphQL operation', {query: query, vars: variables});
        const response = await API.graphql(graphqlOperation(query, variables))
        console.log('GraphQL result', {result: response, query: query, vars: variables})
        if (403 === response.status) {
          console.log('response 403', response)
          // don't retry upon 403
          bail(new Error('Unauthorized'))
          return
        }
        return response
    },
    {
        retries: 0,
        onRetry: catchError
    }
  )
}

const isRequired = message => value => (!!value ? undefined : message);

class OrgCreateForm extends Component {
  constructor(props) {
      super(props);
      this.submitForm = this.submitForm.bind(this)
      this.updateText = this.updateText.bind(this)
      this.state = {
          loading: false,
          name: '',
          url_alias: ''
      };
  }
   
  updateText = (e, {name, value}) => {
    console.log({ event: e, name: name, value: value })
  }

  submitForm = async (values, bag) => {
    
    // check if urlAlias is unique
    console.log('rocks seterrors', bag)
    //console.log('validateUrlAlias', await validateUrlAlias(values.urlalias, bag.setErrors))
    // let validate_success = await validateUrlAlias(values.urlalias, bag.setErrors)
    // if (!validate_success) {
    //   console.log('rocks00', bag)
    //   return
    // }

    // proceed to save the new org
    // let org_values = {input: _.omit(values, 'urlalias')}
    let org_values = {input: values}
    // let org_values = values
    console.log('org_values', org_values)
    const resp = await GqlRetry(QNewOrg, org_values);
    console.log(resp);

    // proceed to save the new urlalias
    //let orgId;
    //orgId = resp.data.addOrgUrlAlias.id;
    //console.log('orgId', orgId);
    // let urlalias_values = {input: {
    //   urlAlias: values.url_alias,
    //   urlAliasOrganizationId: orgId
    // }}
  }

  render() {
    return (
      <Fragment>
        <Formik 
          initialValues={{ 
            url_alias: '',
            name: ''
          }}
          onSubmit={(values, bag) => {
            console.log('rocks8 values', values)
            console.log('rocks9 values', bag)
            this.submitForm(values, bag);
          }}
          render={({
            values,
            errors,
            setErrors,
            touched,
            setFieldValue,
            setFieldTouched,
            validateField,
            validateForm,
          }) => (
            
            <FormFormik>
              <label htmlFor="url_alias">Url Alias</label>
              <StylingFormField>
                <Field
                  name="url_alias"
                  type="text"
                  placeholder="Url Alias"
                />
                <StyledFieldError><ErrorMessage name="url_alias"/></StyledFieldError>
                <div className='success'>{errors.urlaliasSuccess}</div>
                <button
                  type="button"
                  onClick={() => {
                    validateUrlAlias(values.url_alias, setErrors);
                  }}>
                  check availability
                </button>
                <Field
                  name="name"
                  type="text"
                  placeholder="org name"
                />
                <button type="submit">Submit</button>
              </StylingFormField>
              <Debug />
            </FormFormik> 
          )}
        />
      </Fragment>  
    )
  }
}

export default OrgCreateForm;



const StyledFieldError = styled.div`
  color: red;
  margin-bottom: 1em;

`;