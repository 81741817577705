// eslint-disable
// this is an auto generated file. This will be overwritten

export const getOrganization = `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    tags
    sports
    sports_association
    division
    skillLevel
    ageBracket
    contact {
      items {
        id
        name
        email
        phone
        others
      }
      nextToken
    }
    address {
      id
      street
      city
      state
      country
      zipcode
    }
    owner_orig
    owner
    org_administrators
    org_editors
    url_alias
  }
}
`;
export const listOrganizations = `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      tags
      sports
      sports_association
      division
      skillLevel
      ageBracket
      contact {
        nextToken
      }
      address {
        id
        street
        city
        state
        country
        zipcode
      }
      owner_orig
      owner
      org_administrators
      org_editors
      url_alias
    }
    nextToken
  }
}
`;
export const getUrlAliasOrg = `query GetUrlAliasOrg($id: ID!) {
  getUrlAliasOrg(id: $id) {
    id
    entity_id
    redirect
    version
  }
}
`;
export const listUrlAliasOrgs = `query ListUrlAliasOrgs(
  $filter: ModelUrlAliasOrgFilterInput
  $limit: Int
  $nextToken: String
) {
  listUrlAliasOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      entity_id
      redirect
      version
    }
    nextToken
  }
}
`;
export const getUrlAliasEvt = `query GetUrlAliasEvt($id: ID!) {
  getUrlAliasEvt(id: $id) {
    id
    entity_id
    version
  }
}
`;
export const listUrlAliasEvts = `query ListUrlAliasEvts(
  $filter: ModelUrlAliasEvtFilterInput
  $limit: Int
  $nextToken: String
) {
  listUrlAliasEvts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      entity_id
      version
    }
    nextToken
  }
}
`;
export const getAddress = `query GetAddress($id: ID!) {
  getAddress(id: $id) {
    id
    street
    city
    state
    country
    zipcode
  }
}
`;
export const listAddresss = `query ListAddresss(
  $filter: ModelAddressFilterInput
  $limit: Int
  $nextToken: String
) {
  listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      street
      city
      state
      country
      zipcode
    }
    nextToken
  }
}
`;
export const getContact = `query GetContact($id: ID!) {
  getContact(id: $id) {
    id
    name
    email
    phone
    others
    organization {
      id
      name
      tags
      sports
      sports_association
      division
      skillLevel
      ageBracket
      contact {
        nextToken
      }
      address {
        id
        street
        city
        state
        country
        zipcode
      }
      owner_orig
      owner
      org_administrators
      org_editors
      url_alias
    }
  }
}
`;
export const listContacts = `query ListContacts(
  $filter: ModelContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      phone
      others
      organization {
        id
        name
        tags
        sports
        sports_association
        division
        skillLevel
        ageBracket
        owner_orig
        owner
        org_administrators
        org_editors
        url_alias
      }
    }
    nextToken
  }
}
`;
