import React, { Component } from 'react';

import './App.css';

// import route Components here
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom';
// import appSyncConfig from "../aws-exports";
import appSyncConfig from "../aws-exports-manual";
import { ApolloProvider } from "react-apollo";
import AWSAppSyncClient, { defaultDataIdFromObject } from "aws-appsync";
import { Rehydrated } from "aws-appsync-react";

import { ClassLayoutMainDesktop } from './Variables';
import RoutesLayout from './RoutesLayout';

import Amplify, { Auth } from "aws-amplify";
import AuthenticatorSyncSessionToState from './AuthenticatorSyncSessionToState';


const class_name = ClassLayoutMainDesktop;

const client = new AWSAppSyncClient({
  url: appSyncConfig.aws_appsync_graphqlEndpoint,
  region: appSyncConfig.aws_appsync_region,
  auth: {
    type: appSyncConfig.aws_appsync_authenticationType,
    credentials: () => Auth.currentCredentials(),
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
  cacheOptions: {
    dataIdFromObject: (obj) => {
      let id = defaultDataIdFromObject(obj);
      // console.log('cache options obj',obj);
      // console.log('cache options id', id);
      if (!id) {
        const { __typename: typename } = obj;
        console.log('typename', typename);
        switch (typename) {
          case 'Team':
            return `${typename}:${obj.teamId}`;
          default:
            return "sdsdsdsrocks";  // xxxx warning: this should not be id nor a fixed string. but fixed string seems to work .
        }
      }

      return id;
    }
  }
});




const App = () => (
  <Router>      
    <div className={`App  ${class_name}`}>
      <RoutesLayout />
    </div>
  </Router>
)

const WithProvider = () => (
  <ApolloProvider client={client}>
    <Rehydrated>
      <AuthenticatorSyncSessionToState />
      <App />
    </Rehydrated>
  </ApolloProvider>
);

export default WithProvider;


Amplify.configure({
  Auth: {
    // mandatorySignIn: false,
    // REQUIRED - Amazon Cognito Identity Pool ID
    userPoolId: appSyncConfig.aws_user_pools_id,
    // REQUIRED - Amazon Cognito Region
    region: appSyncConfig.aws_appsync_region,
    // OPTIONAL - Amazon Cognito User Pool ID
    identityPoolId: appSyncConfig.aws_cognito_identity_pool_id,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: appSyncConfig.aws_user_pools_web_client_id,
  },
  API: {
    'aws_appsync_graphqlEndpoint': appSyncConfig.aws_appsync_graphqlEndpoint,
    'aws_appsync_region': appSyncConfig.aws_appsync_region,
    'aws_appsync_authenticationType': appSyncConfig.aws_appsync_authenticationType,
  },
});





Amplify.Logger.LOG_LEVEL = 'DEBUG'