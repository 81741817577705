import { createStore, combineReducers } from 'redux';
// import rootReducer from '../reducers/index';
// import { reducer as formReducer } from 'redux-form'
//
// import userIsAuthenticated from '../reducers/userIsAuthenticated';
// import stopCheckingUserSession from '../reducers/stopCheckingUserSession';
// import setUserEmailCurrent from '../reducers/setUserEmailCurrent';
import addArticleReducer from '../reducers/addArticleReducer';
// import articlesReducer from '../reducers/articlesReducer';
import toggleMenuReducer from '../reducers/toggleMenuReducer';
import counterReducer from '../reducers/counterReducer';
import userReducer from '../reducers/userReducer';

// import { userIsAuthenticated, stopCheckingUserSession, setUserEmailCurrent } from '../reducers/userIsAuthenticated';

 

const rootReducer = combineReducers({
  // ...your other reducers here
  // you have to pass formReducer under 'form' key,
  // for custom keys look up the docs for 'getFormState'
  // articles: articlesReducer,
  articles: addArticleReducer,
  // userIsAuthenticated,
  menuToggleIsClicked: toggleMenuReducer,
  count: counterReducer, 
  user: userReducer 
  //stopCheckingUserSession: stopCheckingUserSession, 
  //setUserEmailCurrent: setUserEmailCurrent
  // form: formReducer
})


const store = createStore(rootReducer);
// window.store = store;
console.log(store.getState());
export default store;