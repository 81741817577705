import React, { Fragment } from 'react';
import UserSigninLink from './UserSigninLink'; 
import AnonymousOnly from './AnonymousOnly';
import AuthenticatedOnly from './AuthenticatedOnly';
import { Redirect } from 'react-router-dom';

const UserSignupSuccess = () => {
  return (
    <Fragment>
      <AnonymousOnly>
        <div>
          <h1>Success</h1>
          <p>You have successfully signed up. Please check your email to verify your identity.</p>
          <div><UserSigninLink /></div>
        </div>
      </AnonymousOnly>
      <AuthenticatedOnly>
        <Redirect to="/dashboard" />
      </AuthenticatedOnly>
    </Fragment> 

  );
}



export default UserSignupSuccess;
