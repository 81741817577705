import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Auth } from "aws-amplify";
import { Redirect, NavLink } from 'react-router-dom';
import Error from './Error';
import AnonymousOnly from './AnonymousOnly';
import AuthenticatedOnly from './AuthenticatedOnly';

class UserForgotPasswordForm extends React.Component {
  constructor () {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.state = {
      email: '',
      password: '',
      async_action: '',  // values can be 'loading' or 'success' or 'error'
      error_message: ''
    }
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    console.log('attempting to log in');
    localStorage.setItem('bluuboardSignupEmail', this.state.email);
    try {
      this.setState({
        async_action: 'loading'
      });
      await Auth.forgotPassword(this.state.email);
      console.log('logged in');
      //alert("Logged in");
      console.log(this.props);
      this.setState({
        async_action: 'success'
      });
      console.log(this.props.user);
    } catch (e) {
      this.setState({
        async_action: 'error',
        error_message: e.message
      });
      console.log(e.message);
      console.log('error in logging in');
    }
  }

  validateForm() {
    return undefined !== this.state.email && this.state.email.length > 0;
  }

  render () {
    console.log(this.props.userIsAuthenticated);
    let form;
    const form_original = <form onSubmit={this.handleSubmit}>
        <div>
          <StyledLabel>Email</StyledLabel>
          <StyledInputEmail
            autoFocus
            type="email"
            id="email"
            value={this.state.email}
            onChange={this.handleChange} />
        </div>
        <button disabled={!this.validateForm()} type="submit">
          Send Verification Code
        </button>  
        <div><NavLink exact={true} to="/signup">Signup</NavLink> | <NavLink exact={true} to="/signin">Signin</NavLink></div>

      </form>
    form = form_original;
    let async_component;
    if (this.state.async_action == 'loading') {
      async_component = <div>Checking credentials...</div>
      form = '';
    }   
    if (this.state.async_action == 'success') {
      async_component = <Redirect to="forgot-password-submit-code" />
    } 
    if (this.state.async_action == 'error') {
      async_component = <Error>{this.state.error_message}</Error>
      form = form_original;
    }  
    if (this.state.error_message == 'User does not exist.') {
      form = form_original;
    }
    return (
      <Fragment>
        <AnonymousOnly>
        <div className="login-form">
          <h1>Forgot password</h1>
          {async_component}
          {form}
        </div>
        </AnonymousOnly>
        <AuthenticatedOnly>
          <Redirect to="/dashboard" />
        </AuthenticatedOnly>
      </Fragment> 
    );
  }
}

export default UserForgotPasswordForm;

const StyledLabel = styled.label`
  max-width: 100%;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
`;
const StyledInputEmail = styled.input`
  max-width: 100%;
  font-size: 16px;
  padding: 5px 10px;
  margin-bottom: 1em;
`;
const StyledInputPassword = styled.input`
  max-width: 100%;
  font-size: 16px;
  padding: 5px 10px;
  margin-bottom: 1em;
`;
// ==========================================================================

