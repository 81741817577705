import React, {Fragment} from 'react';
import styled from 'styled-components';
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Redirect, NavLink } from 'react-router-dom';
import Error from './Error';
import { userIsAuthenticated, setUserEmailCurrent } from "../redux/actions/index";
import UserSignupLink from './UserSignupLink';
import UserForgotPasswordLink from './UserForgotPasswordLink';
import AnonymousOnly from './AnonymousOnly';
import AuthenticatedOnly from './AuthenticatedOnly';

class UserSignin extends React.Component {
  constructor () {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.state = {
      email: '',
      password: '',
      async_action: '',  // values can be 'loading' or 'success' or 'error'
      error_message: ''
    }
  }
  componentWillUnmount() {
    this.setState({
      async_action: 'success'
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    console.log('attempting to log in');
    try {
      this.setState({
        async_action: 'loading'
      });
      await Auth.signIn(this.state.email, this.state.password);
      console.log('logged in');
      //alert("Logged in");
      console.log(this.props);
      this.props.userIsAuthenticated();
      this.props.setUserEmailCurrent2(this.state.email);
      // this.setState({
      //   async_action: 'success'
      // });
      this.props.history.push('/dashboard');
      console.log(this.props.user);
    } catch (e) {
      this.setState({
        async_action: 'error',
        error_message: e.message
      });
      console.log(e.message);
      console.log('error in logging in');
    }
  }

  validateForm() {
    return undefined !== this.state.email && this.state.email.length > 0 && undefined !== this.state.password && this.state.password.length > 0;
  }

  render () {
    console.log(this.props.userIsAuthenticated);
    let form;
    const form_original = <form onSubmit={this.handleSubmit}>
        <div>
          <StyledLabel>Email</StyledLabel>
          <StyledInputEmail
            autoFocus
            type="email"
            id="email"
            value={this.state.email}
            onChange={this.handleChange} />
        </div>
        <div>
          <StyledLabel>Password</StyledLabel>
          <StyledInputPassword
            type="password"
            id="password"
            value={this.state.password}
            onChange={this.handleChange} />
        </div>
        <button disabled={!this.validateForm()} type="submit">
          Login  
        </button>  
        <div><UserSignupLink /> | <UserForgotPasswordLink /></div>

      </form>
    form = form_original;
    let async_component;
    if (this.state.async_action == 'loading') {
      async_component = <div>Checking credentials...</div>
      form = '';
    }   
    if (this.state.async_action == 'success') {
      async_component = <Redirect to="/dashboard" />
    } 
    if (this.state.async_action == 'error') {
      async_component = <Error>{this.state.error_message}</Error>
      form = form_original;
    }  
    if (this.state.error_message == 'Incorrect username or password.') {
      form = form_original;
    }
    return (
      <Fragment>
        <AnonymousOnly>
        <div className="login-form">
          <h1>Signin (Cognito)</h1>
          {async_component}
          {form}
        </div>
        </AnonymousOnly>
        <AuthenticatedOnly>
          <Redirect to="/dashboard" />
        </AuthenticatedOnly>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userIsAuthenticated: state.user.userIsAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    userIsAuthenticated: () => {
      dispatch(userIsAuthenticated())
    },
    setUserEmailCurrent2: (email) => {
      dispatch(setUserEmailCurrent(email))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSignin);

const StyledLabel = styled.label`
  max-width: 100%;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
`;
const StyledInputEmail = styled.input`
  max-width: 100%;
  font-size: 16px;
  padding: 5px 10px;
  margin-bottom: 1em;
`;
const StyledInputPassword = styled.input`
  max-width: 100%;
  font-size: 16px;
  padding: 5px 10px;
  margin-bottom: 1em;
`;
// ==========================================================================

