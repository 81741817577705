import React from 'react';
import UserLogout from './UserLogout';

class User extends React.Component {
  render() {
    return (
        <div>
          Rocks 23 user
          
          
        </div>
    );
  }  
}

export default User;